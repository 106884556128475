import { Project } from '../components';

export interface LoginCredentials {
	Email: string;
	Password: string;
}

export interface User extends LoginCredentials {
	/**
	 * Generated by server
	 */
	readonly Id: number;

	FirstName: string;
	LastName: string;
	Timestamp: Date;

	/**
	 * Generated by server
	 */
	// @ts-ignore
	readonly FullName: string;

	Projects: Project[];
}

export class User implements User {
	public readonly Id: number = 0;
	public FirstName = '';
	public LastName = '';
	public Projects: Project[] = [];
	public Timestamp = new Date();

	// @ts-ignore
	get FullName() {
		return `${this.FirstName} ${this.LastName}`;
	}
}
