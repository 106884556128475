import debounce from 'lodash/debounce';
import { Terminal as XTerminal } from 'xterm';
import { Project } from './project';
import { throws } from '../util';

const enum KeyCode {
	BACKSPACE = 8,
	LINE_FEED = 13,
	UP_ARROW = 38,
	DOWN_ARROW = 40,
	DEL = 127,
}

export class Terminal extends HTMLElement {
	protected xterm = new XTerminal({
		rendererType: 'dom',
		screenReaderMode: true,
		// tslint:disable-next-line:no-magic-numbers
		rows: globalThis.innerHeight > 768 ? globalThis.innerHeight > 1080 ? 32 : 23 : 12,
	});
	public static get observedAttributes() {
		return ['hidden'];
	}

	constructor(protected python: typeof globalThis.Sk) {
		super();

		this.python.configure({
			output: (x: string) => this.write(x),
			read(x: any) {
				if (this.builtinFiles == null || this.builtinFiles.files[x] == null) {
					throw new Error(`File not found: '${x}'`);
				}
				return this.builtinFiles.files[x];
			},
			__future__: this.python.python3,
		});
	}

	public connectedCallback() {
		if (this.python.TurtleGraphics == null) {
			throw new Error('Missing globalThis.Sk.TurtleGraphics');
		}

		let buffer: string[] = [];
		const runCommand = () => {
			if (buffer.length === 0) return;

			const commandParts = buffer.join('').split(/\s/g);
			const program = commandParts.shift() as string;
			if (/py(thon)?/.test(program)) {
				try {
					const filename = (commandParts.find(x => /\w+\.py/.test(x)) ?? throws.replUnsupported()) as string;
					const file = (this.parentElement as Project).getFileByFullPath(filename)
						?? throws.fileNotFound(filename) as never;

					this.python.misceval.asyncToPromise(() => {
						return this.python.importMainWithBody('<stdin>', false, file.Text, true);
					});
				} catch (e: any) {
					this.xterm.writeln(e.toString());
				}
			} else {
				this.xterm.writeln('Command not recognized.');
			}

			buffer = [];
		};

		this.xterm.onData(e => {
			if (e.length === 1 && e.charCodeAt(0) === KeyCode.DEL) {
				buffer.pop();
			} else {
				buffer.push(e);
			}
		});
		/**
		 * I am not proud of this. One of us should Do The Right Thing™ here.
		 */
		const ARBITRARY_WAIT_TO_AVOID_MULTIPLE_DOLLAR_SIGNS_IN_TERMINAL = 69;
		const writeDollarSign = debounce(
			() => this.xterm.write('$ '),
			ARBITRARY_WAIT_TO_AVOID_MULTIPLE_DOLLAR_SIGNS_IN_TERMINAL,
		);
		this.xterm.onRender(({ start, end }) => {
			if (end > start) {
				writeDollarSign();
			}
		});
		this.xterm.onLineFeed(runCommand);

		this.xterm.onKey((e: { key: string, domEvent: KeyboardEvent }) => {
			const { domEvent: { keyCode }, key } = e;

			switch (keyCode) {
				case KeyCode.LINE_FEED:
					this.xterm.write('\r\n');
					break;
				case KeyCode.BACKSPACE:
					// tslint:disable-next-line: no-magic-numbers
					if ((this.xterm as any)._core.buffer.x > 2) {
						this.xterm.write('\b \b');
					}
					break;
				case KeyCode.UP_ARROW:
					this.xterm.scrollLines(-1);
					break;
				case KeyCode.DOWN_ARROW:
					this.xterm.scrollLines(1);
					break;
				default:
					this.xterm.write(key);
					break;
			}
		});
	}

	public disconnectedCallback() {
		this.dispose();
	}

	public attributeChangedCallback(name: string, oldValue: any, _newValue: any) {
		if (name !== 'hidden') {
			throw new Error('Only accepting changes to [hidden] attribute.');
		}

		if (typeof oldValue === 'string') {
			if (this.xterm.element?.parentElement == null) {
				this.xterm.open(this);
			} else {
				this.xterm.resize(this.xterm.cols, this.xterm.rows);
			}
			this.xterm.focus();
		}
	}

	public dispose() {
		if (typeof this.python.TurtleGraphics?.reset === 'function') {
			this.python.TurtleGraphics.reset();
		}

		if (this.parentElement == null) {
			this.xterm.dispose();
		} else {
			this.xterm.reset();
			this.xterm.writeln('Terminal');
			this.hidden = true;
		}
	}

	public write(text: string) {
		for (const t of text.split('\n')) {
			this.xterm.paste(t);
			this.xterm.writeln(t);
		}
	}
}
