// (1) Desired editor features:
import 'monaco-editor/esm/vs/editor/browser/controller/coreCommands.js';
import 'monaco-editor/esm/vs/editor/contrib/bracketMatching/bracketMatching.js';
import 'monaco-editor/esm/vs/editor/contrib/comment/comment.js';
import 'monaco-editor/esm/vs/editor/contrib/find/findController.js';
import 'monaco-editor/esm/vs/editor/contrib/linesOperations/linesOperations.js';
import 'monaco-editor/esm/vs/editor/contrib/multicursor/multicursor.js';
import 'monaco-editor/esm/vs/editor/contrib/suggest/suggestController.js';
import 'monaco-editor/esm/vs/editor/contrib/wordHighlighter/wordHighlighter.js';
import 'monaco-editor/esm/vs/editor/contrib/wordOperations/wordOperations.js';

// (2) Import editor:
import { Environment, IRange, editor, languages } from 'monaco-editor/esm/vs/editor/editor.api.js';

// (3) Desired languages:
import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/css/css.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/html/html.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/python/python.contribution.js';

// (4) Configure HTML:
// @ts-ignore
import { registerLanguage } from 'monaco-editor/esm/vs/basic-languages/_.contribution.js';
import tags from './tags.json';

registerLanguage({
	id: 'html',
	extensions: ['.html'],
	aliases: ['html'],
	mimetypes: ['text/html'],
	async loader() {
		// @ts-ignore
		const html = await import('monaco-editor/esm/vs/basic-languages/html/html.js');

		html.conf.autoClosingPairs.push(
			...tags.map(tag => ({ open: `<${tag}>`, close: `</${tag}>` })),
		);

		return html;
	},
});

const completions: (Omit<languages.CompletionItem, 'range'> & { range?: IRange })[] = tags.map(tag => ({
	label: `Insert <${tag}></${tag}>`,
	insertText: `<${tag}></${tag}>`,
	insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
	kind: languages.CompletionItemKind.Text,
	range: undefined,
}));

languages.registerCompletionItemProvider('html', {
	provideCompletionItems(model, position) {
		const { lineNumber } = position;
		const { startColumn, endColumn } = model.getWordUntilPosition(position);
		return {
			suggestions: completions.map(x => {
				x.range = {
					startLineNumber: lineNumber,
					endLineNumber: lineNumber,
					startColumn,
					endColumn,
				};
				return x as languages.CompletionItem;
			}),
		};
	},
});

const workers = Array.from(document.getElementsByTagName('script'))
	.map(x => x.src.replace(`${location.origin}/`, ''))
	.filter(x => x.includes('.worker.'));

(globalThis as (typeof globalThis & { MonacoEnvironment: Environment })).MonacoEnvironment = {
	getWorkerUrl(_moduleId: any, label: string) {
		if (label === 'typescript' || label === 'javascript') {
			label = 'ts';
		}
		return workers.find(x => x.startsWith(label)) ?? workers.find(x => x.startsWith('editor')) ?? '';
	},
};

export { editor as monacoEditor };
