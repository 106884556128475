import anime from 'animejs';
import { NotificationType, NotificationTypeBackgrounds } from './type';

const NOTIFICATION_TRANSLATE_BOUND = 500;

export class Notification extends HTMLElement {
	protected type: NotificationType = 'Log';
	protected readonly header = document.createElement('header');
	protected readonly main = document.createElement('main');
	protected readonly timestamp = document.createElement('timestamp');

	constructor({ text, type }: { text: string, type: NotificationType }) {
		super();

		this.setAttribute('role', 'alert');
		this.setAttribute('aria-atomic', 'true');
		this.setAttribute('aria-live', 'assertive');

		this.setType(type);

		this.main.innerHTML = text;
		this.header.appendChild(this.timestamp);
		this.appendChild(this.header);
		this.appendChild(this.main);
	}

	/**
	 * Change the look & feel of the toast based on its associated log type
	 *
	 * @param type The log type of toast
	 */
	public setType(type: NotificationType) {
		if (this.type != null) {
			this.classList.remove(NotificationTypeBackgrounds[this.type]);
		}

		this.type = type;
		this.classList.add(NotificationTypeBackgrounds[this.type]);
	}

	/**
	 * Show the toast
	 *
	 * @param timeout Length in ms before toast disappears (`false` to set permanently)
	 * @param container Optional container override
	 */
	public show(timeout: false | number) {
		anime({
			targets: this,
			translateX: [NOTIFICATION_TRANSLATE_BOUND, 0],
			duration: 750,
			easing: 'easeOutQuint(0.5, 1)',
			begin: () => {
				const date = new Date();
				this.timestamp.setAttribute('datetime', date.toISOString());
				this.timestamp.innerText = date.toLocaleTimeString(navigator.language).replace(/(:\d{2})(?=\s[AP]M$)/, '');
				this.style.setProperty('opacity', '1');
			},
		});

		return new Promise<void>(resolve => {
			const hideAnimation = anime({
				targets: this,
				translateX: [0, NOTIFICATION_TRANSLATE_BOUND],
				duration: 750,
				autoplay: false,
				easing: 'easeInQuint(0.5, 1)',
				complete: () => {
					this.removeEventListener('click', hideAnimation.play);
					this.removeEventListener('mouseover', resetAnimationOnMouseover);
					this.removeEventListener('mouseout', resumeAnimationOnMouseout);
					this.remove();
					resolve();
				},
			});

			const isAutoClose = typeof timeout === 'number' && Number.isInteger(timeout);
			const makeTimeout = () => (setTimeout as typeof window['setTimeout'])(hideAnimation.play, timeout as number);

			let autoCloseId = isAutoClose ? makeTimeout() : undefined;
			let wasClosing = false;

			const resetAnimationOnMouseover = () => {
				wasClosing = hideAnimation.progress > 0;

				clearTimeout(autoCloseId);
				autoCloseId = undefined;

				hideAnimation.restart();
				hideAnimation.pause();
			};

			const resumeAnimationOnMouseout = () => {
				if (wasClosing) {
					hideAnimation.play();
				} else if (isAutoClose && autoCloseId === undefined) {
					autoCloseId = makeTimeout();
				}
			};

			this.addEventListener('click', hideAnimation.play);
			this.addEventListener('mouseover', resetAnimationOnMouseover);
			this.addEventListener('mouseout', resumeAnimationOnMouseout);
		});
	}

	/**
	 * Hide the toast
	 */
	public hide() {
		this.click();
	}
}
