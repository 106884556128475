import { getTemplateCloneById } from '../../util';

export class PythonPreview extends HTMLElement {
	constructor() {
		super();

		this.appendChild(getTemplateCloneById('python-preview'));
	}

	public connectedCallback() {
		this.hidden = true;
	}
}
