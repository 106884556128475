function throwNoContentDocument(c: { constructor: { name: string } }): never {
	throw new Error(`No contentDocument found for ${c.constructor.name}`);
}
function throwNoOwnerDocumentErr(c: { constructor: { name: string } }): never {
	throw new Error(`No ownerDocument found for ${c.constructor.name}`);
}
function throwInvalidElementErr(el: HTMLElement | null, type: string) {
	throw new Error(`Expected element of type ${type}, but got ${el?.constructor.name ?? 'nothing'} instead.`);
}
function throwFileNotFoundErr(filename: string) {
	throw new Error(`File not found. Filename: ${filename}`);
}
function throwReplUnsupportedErr() {
	throw new Error('REPL mode not implemented. Must pass a filename.');
}

export const throws = {
	noContentDocument: throwNoContentDocument,
	noOwnerDocument: throwNoOwnerDocumentErr,
	invalidElement: throwInvalidElementErr,
	fileNotFound: throwFileNotFoundErr,
	replUnsupported: throwReplUnsupportedErr,
};
