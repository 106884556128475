import { Modal } from './modal';
import { LoginCredentials } from '../../models';
import { Http, Logger } from '../../util';

import googlesvg from 'assets/google.svg';

export class LoginModal extends Modal<LoginCredentials> {
	protected readonly $googleLoginBtn = document.createElement('button');

	constructor() {
		super({
			title: 'Login',
			template: `
				<label for="email">Email</label>
				<input type="email" name="login-email-input" id="login-email" required class="form-control">
				<br />
				<label for="password">Password</label>
				<input type="password" name="login-password-input" id="login-password" class="form-control" minlength="8" required>
			`,
		});

		this.$submitBtn.value = 'Login';
		this.$googleLoginBtn.type = 'button';
		this.$googleLoginBtn.innerHTML = `<img src="${googlesvg}" width="23"> Login with Google`;
		this.$googleLoginBtn.onclick = async () => {
			try {
				await Http.Get('user/login/google');
			} catch (err) {
				Logger.Error(err);
			}
		};
		this.$footer.prepend(this.$googleLoginBtn);
	}

	public onClose() {
		const ret = super.onClose();

		if (ret.canClose) {
			ret.data.Email = (this.querySelector('#login-email') as HTMLInputElement).value;
			ret.data.Password = (this.querySelector('#login-password') as HTMLInputElement).value;
		}

		return ret;
	}
}
