export function getElementById<T extends HTMLElement = HTMLElement>(id: string) {
	const el = document.getElementById(id) as T | null;
	if (el == null) {
		throw new Error(`Element with id '#${id}' not found`);
	}
	return el;
}

export function querySelector<T extends HTMLElement = HTMLElement>(el: Document | HTMLElement, selector: string) {
	const target = el.querySelector<T>(selector);
	if (target == null) {
		throw new Error(`Element with selector '${selector}' not found`);
	}
	return target;
}

export function getTemplateCloneById(id: string) {
	return getElementById<HTMLTemplateElement>(id).content.cloneNode(true) as DocumentFragment;
}

export function getEventTargetAnchor(e: Event) {
	return e.target instanceof HTMLAnchorElement
		? e.target
		: e.target instanceof HTMLElement && e.target.parentElement instanceof HTMLAnchorElement
			? e.target.parentElement
			: null;
}

export function defineCustomElements(...CustomElements: { prototype: HTMLElement; new(...args: any[]): HTMLElement; }[]) {
	for (const CustomElement of CustomElements) {
		globalThis.customElements.define(`cs-${CustomElement.name.toLowerCase()}`, CustomElement);
	}
}
