export const enum Quoted {
	AkioŌtsuka = 'Akio Ōtsuka',
	ChikaoŌtsuka = 'Chikao Ōtsuka',
	HiromiTsuru = 'Hiromi Tsuru',
	KanetoShiozawa = 'Kaneto Shiozawa',
	KenyuHoriuchi = 'Kenyu Horiuchi',
	KikukoInoue = 'Kikuko Inoue',
	KōzōShioya = 'Kōzō Shioya',
	KyokoTerase = 'Kyoko Terase',
	MasaharuSatō = 'Masaharu Satō',
	TakeshiAono = 'Takeshi Aono',
}

export const quotes = [
	{ quote: 'A legend is usually bad news. A person tells it, and they pass it on.', by: Quoted.AkioŌtsuka },
	{ quote: 'Building the future and keeping the past alive are one in the same thing.', by: Quoted.AkioŌtsuka },
	{ quote: 'Choose your own legacy. It\'s for you to decide.', by: Quoted.AkioŌtsuka },
	{ quote: 'Fight for something you believe in and find it for yourself. If you do, pass it on to the future.', by: Quoted.AkioŌtsuka },
	{ quote: 'What is our legacy if we cannot pass the torch?', by: Quoted.AkioŌtsuka },
	{ quote: 'It’s not about changing the world... It’s about respecting the will of others, and believing in your own.', by: Quoted.ChikaoŌtsuka },
	{ quote: 'Human beings can choose the kind of life that they want to live. What’s important is that you choose life... and then live.', by: Quoted.HiromiTsuru },
	{ quote: 'Fighting was the only thing I was good at, but at least I always fought for what I believed in.', by: Quoted.KanetoShiozawa },
	{ quote: 'People will remember the good part – the right part – about you.', by: Quoted.KenyuHoriuchi },
	{ quote: 'Building a legacy involves figuring out what is	wanted, and what needs to be done for that goal.', by: Quoted.KikukoInoue },
	{ quote: 'I am the greatest humanity has to offer, and the lowest.', by: Quoted.KōzōShioya },
	{ quote: 'I die here and my legend begins - too bad you won\'t be around to see the movies.', by: Quoted.KōzōShioya },
	{ quote: 'We start living for ourselves after this.', by: Quoted.KyokoTerase },
	{ quote: 'Silence beats talk when it comes to safety.', by: Quoted.MasaharuSatō },
	{ quote: 'An anemone or clematis plants juice can cause a rash. When pruning them, its a good idea to wear gloves.', by: Quoted.TakeshiAono },
	{ quote: 'Human memories, ideas, culture, and history. Isn\'t it something that should be passed on? Should that information be kept at the mercy of nature?', by: Quoted.TakeshiAono },
	{ quote: 'I need scissors! 61!', by: Quoted.TakeshiAono },
	{ quote: 'Munch, munch... Um? I\'m eating right now. Get back to me later... munch, munch...', by: Quoted.TakeshiAono },
	{ quote: 'I just had no choice but to make you pay for lunch the other day. I\'m really sorry.', by: Quoted.TakeshiAono },
];
