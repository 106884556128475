export interface State { data?: object; title: string; url: string; }

export class Router {
	constructor(protected history: History) { }

	public get stateParams() {
		return this.history.state;
	}

	public go({ data = Object.create(null), title, url }: State) {
		this.history.pushState(data, title, url);
	}

	public update({ data = Object.create(null), title, url }: State) {
		this.history.replaceState(data, title, url);
	}
}
