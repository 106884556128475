import { Parser } from 'htmlparser2';
import { FileSystem } from '../fs';
import { ProjectFile } from '../project';
import { FileTypes } from '../../models';
import { Logger, getTemplateCloneById, throws } from '../../util';

const PREVIEW_FRAME_REFRESH_TIMEOUT = 23;

export class HtmlPreview extends HTMLElement {
	protected selectedFile: ProjectFile = {} as never;
	protected frame: HTMLIFrameElement = document.createElement('iframe');

	constructor() {
		super();

		this.classList.add('col-5');
		this.classList.add('col-md-4');
		this.appendChild(getTemplateCloneById('preview-toolbar'));
	}

	public connectedCallback() {
		this.hidden = true;
		this.frame = this.frame ?? this.ownerDocument?.createElement('iframe') ?? throws.noOwnerDocument(this);
		this.appendChild(this.frame);
	}

	public disconnectedCallback() {
		this.frame.remove();
	}

	public updateFrame(file: ProjectFile, files: ProjectFile[]) {
		this.frame.contentWindow?.location.reload();
		setTimeout(() => this.loadFiles(file, files), PREVIEW_FRAME_REFRESH_TIMEOUT);
	}

	private loadFiles(file: ProjectFile, files: ProjectFile[]) {
		const { Path, Name, Type } = file;

		if (Type !== FileTypes.html) {
			throw new Error('File must be of type HTML');
		}
		if (Path == null) {
			Logger.Warn(`Path null for file ${file.Id}`);
			return;
		}
		if (Name == null) {
			Logger.Warn(`Name null for file ${file.Id}`);
			return;
		}

		const html = files
			.filter(x => x.Type === FileTypes.html)
			.filter(x => x.Path === Path)
			.find(x => x.Name === Name);

		if (html == null) {
			return;
		}

		const frameDoc = this.frame.contentDocument ?? throws.noContentDocument(this.frame);
		const body = frameDoc.body;
		const head = frameDoc.head;

		let element: HTMLElement;
		const parser = new Parser({
			// tslint:disable-next-line: cyclomatic-complexity
			onopentag(name, attribs) {
				switch (name) {
					case 'body':
						element = body;
						break;
					case 'script':
						const { src: scriptSrc, type = 'text/javascript' } = attribs;
						element = frameDoc.createElement('script');
						element.setAttribute('type', type);
						if (scriptSrc != null) {
							const f = files.find(x => scriptSrc === FileSystem.GetFullPath(x));
							element.innerHTML = f?.Text ?? `console.error('${scriptSrc} not found')`;
							element.id = scriptSrc;
						}
						head.appendChild(element);
						break;
					case 'link':
						const { href } = attribs;
						element = frameDoc.createElement('style');
						element.innerHTML = files.find(x => href === FileSystem.GetFullPath(x))?.Text ?? '';
						element.id = href;
						head.appendChild(element);
						break;
					case 'img':
						const img = frameDoc.createElement('img');
						let { src: imgSrc } = attribs;

						if (/^https?:\/\//.test(imgSrc)) {
							img.id = imgSrc;
							img.src = imgSrc;
							element.appendChild(img);
						} else {
							imgSrc = FileSystem.CleanPath(`/${imgSrc}`);
							const imgFile = files.find(x => imgSrc === FileSystem.GetFullPath(x));
							if (imgFile != null) {
								img.id = imgSrc;
								img.src = `${process.env.AZURE_IMAGES_HOST}${FileSystem.CleanPath(`/${imgFile.Hash}.${imgFile.Ext}`)}`;
								element.appendChild(img);
							}
						}

						for (const [key, value] of Object.entries(attribs)) {
							if (key === 'src' && img.hasAttribute('src')) {
								continue;
							}
							img.setAttribute(key, value);
						}
						element = img;
						break;
					default:
						element = element?.appendChild(frameDoc.createElement(name)) ?? frameDoc.createElement(name);
						for (const [key, value] of Object.entries(attribs)) {
							element.setAttribute(key, value);
						}
				}
			},
			ontext(text) {
				element?.appendChild(frameDoc.createTextNode(text));
			},
			onclosetag() {
				element = element?.parentElement as HTMLElement;
			},
		}, {
			decodeEntities: true,
		});
		parser.parseComplete(html.Text);

		setTimeout(() => {
			if (this.frame.contentDocument !== frameDoc) {
				this.updateFrame(file, files);
			}
		});
	}
}
