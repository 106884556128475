import { Modal } from './modal';
import { FileSystem } from '../fs';
import { ProjectFile } from '../project';
import { FileTypes } from '../../models';
import { Http } from '../../util';

export class FileModal extends Modal<ProjectFile> {
	protected file?: ProjectFile;
	protected imgExtRe = /(png|jpg|gif)$/;

	protected readonly $inputs = {
		name: this.querySelector('#new-file-name') as HTMLInputElement,
		path: this.querySelector('#new-folder-path') as HTMLInputElement,
		ext: this.querySelector('#new-file-ext') as HTMLSelectElement,
		text: this.querySelector('#new-file-text') as HTMLTextAreaElement,
		upload: this.querySelector('#new-file-upload') as HTMLInputElement,
		hash: this.querySelector('#new-file-upload-hash') as HTMLInputElement,
	};

	constructor() {
		super({
			title: 'File',
			template: `
				<form name="new-file-form">
					<label for="new-folder-path">Folder</label>
					<input type="text" id="new-folder-path" class="form-control" required>
					<br>
					<label for="new-file-name">Filename</label>
					<input type="text" id="new-file-name" class="form-control" required>
					<label class="sr-only" for="new-file-ext">File Extension</label>
					<select id="new-file-ext">
						<option value="html" selected>.html</option>
						<option value="css">.css</option>
						<option value="js">.js</option>
						<option value="py">.py</option>
					</select>
					<textarea id="new-file-text" hidden></textarea>
					<br>
					<br>
					<label for="new-file-upload" class="sr-only">Upload a file</label>
					<input type="file" name="file" id="new-file-upload" accept="text/javascript, text/html, text/css, image/png, image/gif, image/jpeg">
					<input id="new-file-upload-hash" aria-label="Uploaded file hash" hidden>
				</form>
			`,
		});
	}

	public async * open(file: Partial<ProjectFile>): AsyncGenerator<ProjectFile | Error> {
		this.file = new ProjectFile(file);
		this.onOpen();

		while (true) {
			try {
				yield await this.createFormHandlerPromise();
			} catch (err: any) {
				yield new Error(err);
			}
		}
	}

	public onOpen() {
		if (this.file == null) {
			return;
		}

		super.onOpen();

		const { Ext = FileTypes.html, Path = '/', Name = '', Text = '' } = this.file ?? {};

		this.$inputs.upload.hidden = this.file.Id > 0;
		this.$submitBtn.value = this.$inputs.upload.hidden ? 'Update' : 'Add';
		this.$inputs.path.value = Path;
		this.$inputs.name.value = Name;
		this.$inputs.ext.value = Ext;
		this.$inputs.text.value = Text;

		if (this.$inputs.upload.hidden === false) {
			this.$inputs.upload.onchange = async () => {
				const file = this.$inputs.upload.files?.item(0);
				if (file != null) {
					const [rawExt, ...nameParts] = file.name.split('.').reverse();
					this.$inputs.name.value = nameParts.reverse().join('.');

					const ext = rawExt.toLowerCase();
					if (this.imgExtRe.test(ext)) {
						const option = document.createElement('option');
						option.innerText = `.${ext}`;
						option.value = ext;
						option.selected = true;

						const data = new FormData();
						data.set('file', file, file.name);

						try {
							this.$submitBtn.value = 'Uploading...';
							this.$submitBtn.disabled = true;
							this.$inputs.ext.options.add(option);
							this.$inputs.hash.value = await Http.Post('project/file', data);
						} finally {
							this.$submitBtn.value = 'Add';
							this.$submitBtn.disabled = false;
						}
					} else if (file.size > 0) {
						const reader = new FileReader();
						reader.onload = () => {
							this.$inputs.text.value = reader.result as string;
						};
						reader.readAsText(file);
					}

					this.$inputs.ext.value = ext;
				}
			};
		}
	}

	public onClose() {
		if (this.file == null) {
			return { data: this.file as unknown as ProjectFile, canClose: false };
		}

		super.onClose();

		const ext = (this.$inputs.ext.selectedOptions.item(0)?.value ?? FileTypes.html) as keyof typeof FileTypes;
		const fileName = `${this.$inputs.name.value}.${ext}`;
		const canClose = this.$inputs.ext.options.selectedIndex > -1;

		if (canClose) {
			this.file.Path = FileSystem.CleanPath(this.$inputs.path.value);
			this.file.Name = this.$inputs.name.value;
			this.file.Ext = ext;
			this.file.Type = FileTypes[ext];
			this.file.Hash = this.$inputs.hash.value;

			if (this.$inputs.text.value.length > 0) {
				this.file.Text = this.$inputs.text.value;
			} else {
				this.file.Text = FileSystem.GetDefaultText(this.file);
			}

			for (let i = 0; i < this.$inputs.ext.options.length; i++) {
				const option = this.$inputs.ext.options.item(i);
				if (option != null && this.imgExtRe.test(option.value)) {
					option.remove();
				}
			}
			this.$inputs.upload.onchange = null;
		} else {
			this.$inputs.ext.setCustomValidity(`Text files must end with '.js', '.css', '.html' or '.py'; uploaded image files must end with '.png  '.jpg' or '.gif'. (your filename: ${fileName})`);
		}

		return { data: this.file, canClose };
	}
}
