import { Cache, CacheFactory } from 'cachefactory';
import cloneDeep from 'lodash/cloneDeep';

const cacheFactory = new CacheFactory();
const shortTermIdCache = new Set<number>();
const longTermIdCache = new Set<number>();

export abstract class Storage {
	protected readonly cache: Cache;
	constructor(cache: Cache) {
		this.cache = cache;
	}

	public remove(key: string) {
		this.cache.remove(key);
	}

	public removeByHref(key: string) {
		this.remove(this.getLocationKey(key));
	}

	public get<T>(key: string): T | undefined {
		const item = this.cache.get(key) ?? '';
		return (
			item === 'undefined'
				? undefined
				: item.length > 0
					? JSON.parse(item)
					: undefined
		);
	}

	public getByHref<T>(key: string) {
		return this.get<T>(this.getLocationKey(key));
	}

	public set<T>(key: string, item: T) {
		try {
			item = cloneDeep(item);
			for (const k of Object.keys(item) as (keyof T)[]) {
				// invalid dates get serialized as `new Date()` -- we want them nulled out
				const d = item[k];
				if (d instanceof Date && Number.isNaN(d.valueOf())) {
					item[k] = undefined as never;
				}
			}
		} catch {
			// intentionally swallowing errors (mainly from Object.keys throwing on invalid input)
			// might set up a "proper" validation heuristic at some point
		}
		return this.cache.put(key, JSON.stringify(item));
	}

	public setByHref<T>(key: string, item: T) {
		return this.set(this.getLocationKey(key), item);
	}

	protected getLocationKey(key: string) {
		return `${key} @ ${location.href}`;
	}
}

export class ShortTermStorage extends Storage {
	constructor(id: number) {
		if (shortTermIdCache.has(id)) {
			throw new Error(`ShortTermStorage id ${id} in use`);
		}
		shortTermIdCache.add(id);
		const cache = cacheFactory.createCache(`ShortTermStorage${id}`, { storageMode: 'sessionStorage' });
		super(cache);
	}
}

export class LongTermStorage extends Storage {
	constructor(id: number) {
		if (longTermIdCache.has(id)) {
			throw new Error(`LongTermStorage ${id} in use`);
		}
		longTermIdCache.add(id);
		super(cacheFactory.createCache(
			`LongTermStorage${id}`,
			{ deleteOnExpire: 'aggressive', storageMode: 'localStorage' },
		));
	}
}
