export type Radio = (code: string, message: any) => void;

export class Station {
	private readonly registry = new Set<Radio>();
	public subscribe(radio: Radio) {
		if (this.registry.has(radio)) {
			return;
		}
		this.registry.add(radio);
	}
	public unsubscribe(radio: Radio) {
		this.registry.delete(radio);
	}

	/**
	 * Send a message to all stations registered with this radio.
	 */
	public broadcast(code: string, message: any) {
		for (const radio of this.registry) {
			radio(code, message);
		}
	}
}

export const GlobalStation = new Station();
