// tslint:disable:no-console
export class Logger {
	/**
	 * Invokes `Console.prototype.debug`
	 *
	 * @param items list of items to log
	 */
	public static Debug(...items: any[]) {
		if (items.every(x => !x)) return;
		console.debug(...items);
	}

	/**
	 * Invokes `Console.prototype.error`
	 *
	 * @param items list of items to log
	 */
	public static Error(...items: any[]) {
		if (items.every(x => !x)) return;
		console.error(...items);
	}

	/**
	 * Invokes `Console.prototype.info`
	 *
	 * @param items list of items to log
	 */
	public static Info(...items: any[]) {
		if (items.every(x => !x)) return;
		console.info(...items);
	}

	/**
	 * Invokes `Console.prototype.warn`
	 *
	 * @param items list of items to log
	 */
	public static Warn(...items: any[]) {
		if (items.every(x => !x)) return;
		console.warn(...items);
	}

	/**
	 * Invokes `Console.prototype.log`
	 *
	 * @param items list of items to log
	 */
	public static Log(...items: any[]) {
		if (items.every(x => !x)) return;
		console.log(...items);
	}

	/**
	 * Invokes `Console.prototype.log`
	 *
	 * @param items list of items to log
	 */
	public static Success(...items: any[]) {
		Logger.Log(...items);
	}
}
// tslint:enable:no-console
