import { ProjectFileContainer } from './container';
import { FileModal } from '../modal';
import { ProjectFile } from '../project';
import { GlobalStation, Logger, Storage, getElementById, getTemplateCloneById, throws } from '../../util';
import { FileTypes } from '../../models';

import fsDefaults from './defaults.json';
const { CSS, HTML } = fsDefaults;

const ITEM_OPACITY_REMOVAL_TIMEOUT = 500;

export class FileSystem extends HTMLElement {
	public static GetDefaultText(file: ProjectFile): string {
		switch (file.Type) {
			case FileTypes.html:
				return HTML.replace('$$PATH$$', `${file.Path.split('/').filter(x => x.length > 0).map(() => '..').join('/')}/`);
			case FileTypes.css:
				return CSS;
			default:
				return '';
		}
	}
	public static CleanPath(path: string) {
		return path.length === 0 ? '/' : path.replace(/[/|\\]+/g, '/');
	}
	public static GetFullPath(file: ProjectFile, pathOverride = file.Path) {
		return FileSystem.CleanPath(`${pathOverride}/${file.Name}.${file.Ext}`);
	}

	public get currentPath() {
		return this.currentFolder?.id ?? '/';
	}

	protected get document() {
		return this.ownerDocument ?? throws.noOwnerDocument(this);
	}

	protected readonly folders = new Map<string, HTMLUListElement>();
	protected readonly files = new Map<string, ProjectFileContainer>();
	protected readonly root: HTMLUListElement;
	protected readonly pathInput = this.document.createElement('input');

	protected currentFolder?: HTMLUListElement;
	protected currentFile?: HTMLLIElement;

	protected get lastSelectedFileId() {
		return this.storage.getByHref('lastFileId');
	}
	protected set lastSelectedFileId(id: string | undefined) {
		this.storage.setByHref('lastFileId', id);
	}

	constructor(protected storage: Storage, protected fileModal: FileModal) {
		super();

		this.classList.add('col-2');
		this.classList.add('col-md-3');
		this.appendChild(getTemplateCloneById('files-toolbar'));

		this.root = this.addFolder('/');
		this.root.onclick = null;
		this.currentFolder = this.root;

		this.pathInput.style.setProperty('position', 'absolute');
		this.pathInput.style.setProperty('left', '-23rem');
		this.pathInput.setAttribute('aria-hidden', 'true');
		this.appendChild(this.pathInput);
	}

	public connectedCallback() {
		this.currentFolder = this.root;
		if (this.root.parentElement != null) {
			this.root.remove();
		}
		this.appendChild(this.root);
	}

	public disconnectedCallback() {
		this.dispose();
	}

	public dispose() {
		this.files.clear();
		this.folders.clear();
		this.folders.set('/', this.root);

		while (this.root?.firstElementChild != null) {
			this.root.firstElementChild.remove();
		}
	}

	public getFileId(file: ProjectFile) {
		return FileSystem.GetFullPath(file);
	}

	public getFolderId(file: ProjectFile) {
		return FileSystem.CleanPath(file.Path);
	}

	public getFileContainer(file: ProjectFile) {
		return this.files.get(FileSystem.GetFullPath(file)) as ProjectFileContainer;
	}

	public addFiles(files: ProjectFile[]) {
		this.currentFile = undefined;

		if (files.length === 0) {
			setTimeout(() => {
				getElementById<HTMLButtonElement>('new-file').click();
			});
			return;
		}

		for (const file of files) {
			this.addFile(file);
		}

		const { lastSelectedFileId } = this; // only run get() code once
		if (typeof lastSelectedFileId === 'string' && lastSelectedFileId.length > 0) {
			const file = files.find(x => this.getFileId(x) === lastSelectedFileId);
			if (file != null) {
				this.selectFile(this.getFileContainer(file));
			}
		}

		if (this.currentFile == null) {
			const [firstFile] = files;
			this.selectFile(this.getFileContainer(firstFile));
		}
	}

	public addFile(file: ProjectFile) {
		const fullPath = FileSystem.GetFullPath(file);

		if (this.files.has(fullPath)) {
			throw new Error(`File ${fullPath} already exists.`);
		}

		const folderId = this.getFolderId(file);
		let list = this.document.getElementById(folderId);

		if (list == null) {
			const parts = file.Path.split('/').map(x => x.length === 0 ? '/' : x);
			for (let i = 0; i < parts.length; i++) {
				this.currentFolder = list = this.addFolder(parts.slice(0, i).join('/'), parts[i]);
			}
		}

		const el = this.document.createElement('li');
		el.appendChild(this.document.createTextNode(`${file.Name}.${file.Ext}`));
		el.draggable = true;
		el.id = this.getFileId(file);

		const deleteFile = (timeout = ITEM_OPACITY_REMOVAL_TIMEOUT) => {
			el.style.setProperty('opacity', '0');

			el.onclick = null;
			del.onclick = null;
			ren.onclick = null;

			setTimeout(() => {
				this.files.delete(fullPath);
				file.IsDeleted = true;
				file.IsModified = true;

				const { parentElement } = el;

				el.remove();
				if (parentElement?.children.length === 0) {
					parentElement.remove();
				}

				if (el.classList.contains('selected')) {
					const [[, fileContainer]] = this.files.entries();
					this.selectFile(fileContainer);
				}
			}, timeout);
		};

		el.onclick = () => {
			this.selectFile({ file, el });
		};

		//#region drag-and-drop api
		el.ondragstart = e => e.dataTransfer?.setData('text/plain', el.id);
		el.ondragend = e => {
			let folder = e.target instanceof HTMLUListElement
				? e.target
				: e.target instanceof HTMLLIElement && e.target.parentElement instanceof HTMLUListElement
					? e.target.parentElement
					: undefined;

			if (folder != null && folder.id !== file.Path) {
				const path = FileSystem.GetFullPath(file, folder.id);
				if (this.files.has(path)) {
					el.remove();
					folder = this.getFolder(file);
					if (folder != null) {
						folder.appendChild(el);
						this.arrangeItems(folder);
					}
					alert(`Unable to move ${FileSystem.GetFullPath(file)} -> ${path} as a file with that name already exists.`);
				} else {
					file.Path = folder.id;
					file.IsModified = true;
					deleteFile(/** skip normal deletion animation */ 0);
					setTimeout(() => {
						file.IsDeleted = undefined;
						this.selectFile(this.addFile(file));
					}, 0);
				}
			}

			return false;
		};
		//#endregion

		const group = el.appendChild(this.document.createElement('div'));

		const copy = group.appendChild(this.document.createElement('button'));
		copy.appendChild(getTemplateCloneById('copy-icon'));
		copy.title = 'Copy File Path';
		copy.onclick = e => {
			e.stopPropagation();

			this.pathInput.value = fullPath;
			this.pathInput.select();
			this.document.execCommand('copy');
			alert('Copied file path to clipboard');
			this.pathInput.value = '';
		};

		const ren = group.appendChild(this.document.createElement('button'));
		ren.appendChild(getTemplateCloneById('rename-icon'));
		ren.title = 'Rename File';
		ren.onclick = async e => {
			e.stopPropagation();

			const fileGenerator = this.fileModal.open(file);

			for await (const newFile of fileGenerator) {
				if (newFile instanceof Error) {
					return Logger.Error(newFile.message);
				}
				try {
					const newFilePath = FileSystem.GetFullPath(newFile);
					if (this.files.has(newFilePath)) {
						throw new Error(`Unable to rename ${FileSystem.GetFullPath(file)} -> ${newFilePath} as a file with that name already exists.`);
					}

					file.Name = newFile.Name;
					file.Path = newFile.Path;
					file.Ext = newFile.Ext;
					file.IsModified = true;

					fileGenerator.return(newFile);
				} catch (e: any) {
					Logger.Error(e);
					alert(e.message);
				}
			}

			deleteFile(0);
			this.addFile(file);
			this.fileModal.close();
		};

		const del = group.appendChild(this.document.createElement('button'));
		del.appendChild(getTemplateCloneById('trash-icon'));
		del.title = 'Delete File';
		del.onclick = e => {
			e.stopPropagation();

			if (confirm('Are you sure you want to delete this file?')) {
				deleteFile();
			}
		};

		if (list instanceof HTMLUListElement) {
			list.appendChild(el);
			list.classList.add('open');
			list.classList.remove('closed');
			this.arrangeItems(list);
		} else {
			throws.invalidElement(list, HTMLUListElement.name);
		}

		this.files.set(fullPath, { file, el });
		return { el, file };
	}

	public arrangeItems(list: HTMLUListElement) {
		const { firstChild } = list;

		if (firstChild?.nodeName === '#text') {
			firstChild.remove();
		}

		const children = [...list.children].sort((x, y) => y.id.localeCompare(x.id));

		for (const f of children.filter(x => x instanceof HTMLLIElement)) {
			f.remove();
			list.prepend(f);
		}

		for (const f of children.filter(x => x instanceof HTMLUListElement)) {
			f.remove();
			list.prepend(f);
		}

		if (firstChild?.nodeName === '#text') {
			list.prepend(firstChild);
		}
	}

	public addFolder(path: string, folderName = '') {
		const folderId = FileSystem.CleanPath(`${path}/${folderName}`);
		let folder = this.folders.get(folderId);
		if (folder != null) {
			return folder;
		}

		folder = this.document.createElement('ul');
		folder.id = folderId;
		folder.innerText = folderName;
		folder.classList.add('open');
		folder.onclick = e => {
			globalThis.getSelection()?.removeAllRanges();
			if (e.target instanceof HTMLUListElement) {
				this.currentFolder?.classList.remove('selected');
				e.target.classList.toggle('open');
				e.target.classList.toggle('closed');
				this.currentFolder = e.target;
				this.currentFolder.classList.add('selected');
				e.stopPropagation();
			}
		};
		folder.ondragover = e => e.preventDefault();
		folder.ondrop = e => {
			e.preventDefault();
			const f = e.target instanceof HTMLUListElement
				? e.target
				: e.target instanceof HTMLLIElement && e.target.parentElement instanceof HTMLUListElement
					? e.target.parentElement
					: null;

			if (f != null) {
				const { el } = this.files.get(e.dataTransfer?.getData('text') ?? '') ?? {};
				if (el != null) {
					el.remove();
					f.appendChild(el);
				}
			}
		};

		if (this.currentFolder != null) {
			this.currentFolder.appendChild(folder);
			this.currentFolder.classList.add('open');
			this.currentFolder.classList.remove('closed');
			this.arrangeItems(this.currentFolder);
		} else {
			this.appendChild(folder);
		}

		this.folders.set(folderId, folder);
		return folder;
	}

	public getFolder(file: ProjectFile) {
		return this.folders.get(this.getFolderId(file));
	}

	protected selectFile({ file, el }: ProjectFileContainer) {
		this.currentFile?.classList.remove('selected');
		this.currentFile = el;
		this.currentFile.classList.add('selected');
		this.currentFolder = this.currentFile.parentElement as HTMLUListElement;
		this.lastSelectedFileId = this.currentFile.id;
		GlobalStation.broadcast('file-select', file);
	}
}
