import { Modal } from './modal';
import { User } from '../../models';
import { getEventTargetAnchor, getTemplateCloneById } from '../../util';

const MAIN_CLEAR_TIMEOUT = 500;

export class ProfileModal extends Modal {
	constructor() {
		super({
			title: 'Profile',
			template: '',
		});
		this.$submitBtn.value = 'Close';
	}

	public async * open({ Email, FullName, Projects, Timestamp }: User): AsyncGenerator<unknown | Error> {
		this.onOpen();
		this.onclick = e => {
			const target = getEventTargetAnchor(e);
			if (target instanceof HTMLAnchorElement) {
				this.$submitBtn.click();
			}
		};

		this.$main.appendChild(getTemplateCloneById('profile-detail-list'));
		this.$main.innerHTML = this.$main.innerHTML.replace(`{{Email}}`, Email);
		this.$main.innerHTML = this.$main.innerHTML.replace(`{{FullName}}`, FullName);
		this.$main.innerHTML = this.$main.innerHTML.replace(
			`{{Timestamp}}`,
			Timestamp == null
				? 'Before they knew...'
				: new Intl.DateTimeFormat().format(
					Timestamp instanceof Date
						? Timestamp
						: Date.parse(Timestamp),
				),
		);

		const projectList = document.createElement('ul');
		this.$main.appendChild(projectList);

		for (const project of Projects) {
			const projectItem = document.createElement('li');
			projectItem.innerHTML = `<a href="/${project.URL}">${project.Name || project.URL}</a>`;
			projectList.appendChild(projectItem);
		}

		while (true) {
			try {
				yield await this.createFormHandlerPromise();
			} catch (err: any) {
				yield new Error(err);
			}
		}
	}

	public close() {
		super.close();
		this.onclick = null;

		setTimeout(() => {
			while (this.$main.firstElementChild != null) {
				this.$main.firstElementChild.remove();
			}
		}, MAIN_CLEAR_TIMEOUT);
	}
}
