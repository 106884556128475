import { FileTypes } from '../../models';

export class ProjectFile {
	/**
	 * Generated by server
	 */
	public readonly Id: number;

	/**
	 * Generated by server
	 */
	public readonly FileVersion: number;

	/**
	 * Generated by server
	 */
	public readonly ProjectVersion: number;

	/**
	 * Generated by server
	 */
	public readonly ProjectId: number;

	/**
	 * Generated by server
	 */
	public readonly Timestamp: Date | string | number = new Date();

	public Path: string;
	public Name: string;
	public Ext: keyof typeof FileTypes;
	public Type: FileTypes;
	public Text: string;
	public Hash?: string;

	/**
	 * Generated by client
	 */
	public IsModified?: boolean;
	public IsDeleted?: true;

	/**
	 * Generated by client
	 */
	public readonly OriginalText: string;

	constructor({
		Id = 0,
		ProjectId = 0,
		ProjectVersion = 0,
		FileVersion = 0,
		Path = '/',
		Name = '',
		Ext = 'html',
		Type = FileTypes[Ext],
		Text = '',
		OriginalText = Text,
		Timestamp = new Date(),
		Hash,
		IsDeleted,
	}: Partial<ProjectFile> = {}) {
		this.Id = Id;
		this.ProjectId = ProjectId;
		this.ProjectVersion = ProjectVersion;
		this.FileVersion = FileVersion;
		this.Path = Path;
		this.Name = Name;
		this.Ext = Ext;
		this.Type = Type;
		this.Text = Text;
		this.Hash = Hash;
		this.Timestamp = Timestamp;
		this.OriginalText = OriginalText;
		this.IsDeleted = IsDeleted;
	}
}
