import { EditorLanguages } from './languages';
import { monacoEditor } from './monaco';
import { EditorThemes } from './themes';

import { ProjectFile } from '../project';
import { Storage, getTemplateCloneById } from '../../util';

export class Editor extends HTMLElement {
	public static CreateMonacoEditor(editor: Editor) {
		return monacoEditor.create(editor, {
			theme: editor.theme,
			minimap: { enabled: false },
			scrollBeyondLastLine: false,
			model: null,
		});
	}

	public get theme() {
		return this.storage.get('currentTheme') ?? EditorThemes.Dark;
	}
	public set theme(value: EditorThemes) {
		monacoEditor.setTheme(value);
		this.storage.set('currentTheme', value);
	}

	protected disposables: [{ dispose(): void; }?, { dispose(): void; }?] = [];
	protected monaco: monacoEditor.IStandaloneCodeEditor;
	protected currentModel: monacoEditor.ITextModel | null = null;
	protected _currentFile = new ProjectFile();
	public get currentFile() {
		return this._currentFile;
	}

	protected readonly storage: Storage;
	protected readonly models = new Map<EditorLanguages, monacoEditor.ITextModel>([
		[EditorLanguages.JS, monacoEditor.createModel('', 'javascript')],
		[EditorLanguages.CSS, monacoEditor.createModel('', 'css')],
		[EditorLanguages.HTML, monacoEditor.createModel('', 'html')],
		[EditorLanguages.PY, monacoEditor.createModel('', 'python')],
	]);

	constructor(storage: Storage) {
		super();

		this.storage = storage;
		this.monaco = Editor.CreateMonacoEditor(this);

		this.classList.add('col-10');
		this.classList.add('col-md-9');
		this.appendChild(getTemplateCloneById('editor-toolbar'));
	}

	public isThemeActive(theme: EditorThemes) {
		return this.theme === theme;
	}

	public connectedCallback() {
		if (this.monaco == null) {
			this.monaco = Editor.CreateMonacoEditor(this);
		}
	}

	public disconnectedCallback() {
		this.dispose();
		this.monaco.dispose();
	}

	public dispose() {
		this.disposables.forEach(x => x?.dispose());
		this.disposables = [];
		for (const [, model] of this.models) {
			model.setValue('');
		}
	}

	public toggleSizes() {
		this.classList.toggle('col-5');
		this.classList.toggle('col-10');
		this.classList.toggle('col-md-9');
		this.resize();
	}

	public resize() {
		const bounds = this.getBoundingClientRect();
		// tslint:disable-next-line: no-magic-numbers
		bounds.height -= 78;
		this.monaco.layout(bounds);
	}

	public openFile(file?: ProjectFile) {
		if (file == null) {
			return;
		}

		const model = this.models.get(EditorLanguages[file.Type as keyof typeof EditorLanguages]);
		if (model == null) {
			throw new Error(`Bad language id: ${file.Type}`);
		}

		this._currentFile = { ...file };
		this.currentModel = model;
		this.currentModel.setValue(file.Text);
		this.monaco.setModel(model);

		setTimeout(() => {
			this.resize();
			this.monaco.focus();
		});
	}

	public onContentChange(listener: (file: ProjectFile) => void) {
		this.disposables.push(this.monaco.onDidChangeModelContent(() => {
			this._currentFile.Text = this.currentModel?.getValue() ?? '';
			listener(this._currentFile);
		}));
	}
}
