export abstract class Modal<T = unknown> extends HTMLElement {
	protected static HIDE_DELAY = 300;
	protected static SUBMIT_BTN_DISABLE_DELAY = 132;

	/**
	 * see https://stackoverflow.com/a/2117523
	 */
	protected readonly uniqueId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
		.replace(/[xy]/g, c => {
			// tslint:disable:no-bitwise no-magic-numbers
			const r = Math.random() * 16 | 0;
			const v = c === 'x' ? r : (r & 0x3 | 0x8);
			// tslint:enable:no-bitwise no-magic-numbers
			return v.toString(16);
		})
		.replace(/[-]|[,]/g, '');

	protected readonly $backdrop = document.createElement('div');
	protected readonly $form = document.createElement('form');
	protected readonly $header = document.createElement('header');
	protected readonly $title = document.createElement('h5');
	protected readonly $main = document.createElement('main');
	protected readonly $footer = document.createElement('footer');
	protected readonly $cancelBtn = document.createElement('button');
	protected readonly $submitBtn = document.createElement('input');
	private submitBtnDisableTimeout: number | undefined;

	constructor(protected readonly options: ModalOptions) {
		super();

		this.$backdrop.classList.add('backdrop');
		this.$title.id = `modal-title-${this.uniqueId}`;

		this.$cancelBtn.classList.add('light');
		this.$cancelBtn.innerText = 'Cancel';
		this.$cancelBtn.type = 'button';

		this.$submitBtn.classList.add('success');
		this.$submitBtn.type = 'submit';

		this.$header.appendChild(this.$title);

		this.$footer.appendChild(this.$cancelBtn);
		this.$footer.appendChild(this.$submitBtn);

		this.$form.appendChild(this.$header);
		this.$form.appendChild(this.$main);
		this.$form.appendChild(this.$footer);

		this.$form.setAttribute('role', 'document');
		this.$form.name = this.constructor.name.toLowerCase().replace('modal', '-form');

		this.hidden = true;
		this.setAttribute('role', 'dialog');
		this.setAttribute('tabindex', '-1');
		this.setAttribute('aria-labelledby', this.$title.id);
		this.setAttribute('aria-live', 'polite');
		this.setAttribute('aria-modal', 'true');

		this.appendChild(this.$form);

		const {
			title = 'Set the <code>title</code> property to replace me :)',
			template = '<p class="lead">Set the <code>template</code> property to replace me :)</p>',
		} = this.options;

		this.$title.innerHTML = typeof title === 'function' ? title() : title;
		this.$main.innerHTML =
			typeof template === 'function' ? template() : template;
	}

	public async * open(_?: any): AsyncGenerator<T | Error> {
		this.onOpen();

		while (true) {
			clearTimeout(this.submitBtnDisableTimeout);
			setTimeout(() => this.$submitBtn.disabled = false, Modal.SUBMIT_BTN_DISABLE_DELAY);
			try {
				yield await this.createFormHandlerPromise();
			} catch (err: any) {
				yield new Error(err);
			}
		}
	}

	public close() {
		this.style.removeProperty('opacity');
		this.$backdrop.style.removeProperty('opacity');

		setTimeout(() => {
			this.hidden = true;
			this.$backdrop.remove();
			this.$form.reset();

		}, Modal.HIDE_DELAY);
	}

	public onOpen(): void {
		this.hidden = false;
		this.parentElement?.appendChild(this.$backdrop);
		this.$submitBtn.disabled = false;

		const input = this.querySelector('input');
		if (input != null) {
			input.focus();
			this.$cancelBtn.hidden = input === this.$submitBtn;
		}

		setTimeout(() => {
			this.style.setProperty('opacity', '1');
			this.$backdrop.style.setProperty('opacity', '1');
		});
	}

	public onClose(): { data: T, canClose: boolean } {
		const canClose = this.$form.reportValidity();
		return {
			data: Object.create(null) as T,
			canClose,
		};
	}

	protected createFormHandlerPromise() {
		return new Promise<T>((resolve, reject) => {
			this.$cancelBtn.onclick = () => {
				this.close();
				reject();
			};

			this.$form.onsubmit = e => {
				this.submitBtnDisableTimeout = setTimeout(
					() => this.$submitBtn.disabled = true,
					Modal.SUBMIT_BTN_DISABLE_DELAY,
				);
				const { data, canClose } = this.onClose();
				if (canClose) {
					resolve(data);
				}
				e.preventDefault();
			};
		});
	}
}

export interface ModalOptions {
	/**
	 * String representing the modal's title
	 */
	title?: string | (() => string);

	/**
	 * Inline template representing the modal's content
	 */
	template?: string | (() => string);
}
