import { Logger } from './log';

const HTTP_STATUS_CODE_CREATED = 201;

export class Http {
	public static async Get(endpoint: string) {
		const init = Http.MakeRequestInit('GET');
		const url = Http.MakeUrlFromEndpoint(endpoint);
		const request = await globalThis.fetch(url, init);
		return Http.HandleResponse(request, init);
	}

	public static async Post(endpoint: string, data?: any) {
		const init = Http.MakeRequestInit('POST', data);
		const url = Http.MakeUrlFromEndpoint(endpoint);
		const request = await globalThis.fetch(url, init);
		return Http.HandleResponse(request, init);

	}

	public static MakeUrlFromEndpoint(url: string) {
		return `${process.env.API_HOST}${url.startsWith('/') ? '' : '/'}${url}`;
	}

	public static MakeRequestInit(method: RequestInit['method'], data?: any) {
		const isFormData = data instanceof FormData;
		const init = {
			mode: 'cors',
			method,
			referrerPolicy: 'strict-origin-when-cross-origin',
			credentials: 'include',
			redirect: 'manual',
			headers: new Headers({
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json;charset=utf-8',
			}),
			body: isFormData
				? data
				: method === 'GET'
					? undefined
					: JSON.stringify(data),
		};
		if (isFormData) {
			init.headers.delete('Content-Type');
		}
		return init as RequestInit & { headers: Headers };
	}

	public static async HandleResponse(rsp: Response, init: RequestInit) {
		if (rsp.ok) {
			if (rsp.status === HTTP_STATUS_CODE_CREATED && rsp.headers.has('Location')) {
				return globalThis.location.assign(rsp.headers.get('Location') as string);
			}

			const contentType = rsp.headers.get('Content-Type');
			if (typeof contentType === 'string') {
				rsp = rsp.clone();

				switch (contentType.replace(/[;].*$/g, '')) {
					case 'application/json':
						return rsp.json();
					default:
						return rsp.text();
				}
			}
			return;
		}

		return Http.ThrowResponse(rsp, rsp.url, init.method as 'GET' | 'POST');
	}

	public static async ThrowResponse(rsp: Response, url: string, type: 'GET' | 'POST') {
		Logger.Error(`Error code ${rsp.status} for HTTP-${type} on endpoint '${url}'`);

		let text: string;
		try {
			text = await rsp.text();
		} catch {
			text = rsp.statusText;
		}

		throw new Error(text);
	}
}
