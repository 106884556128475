import { NotificationType } from './type';
import { Notification } from './notification';

export class Notifier extends HTMLElement {
	protected readonly notifications: Notification[] = [];

	constructor(private isProd = false) {
		super();
	}

	public disconnectedCallback() {
		this.notifications.forEach(x => x.hide());
	}

	/**
	 * Display an error notification
	 *
	 * @param text Display text
	 * @param isTemporary Whether the notification should disappear automatically (false by default)
	 */
	public error(text: string, isTemporary = true) {
		this.notify(text, 'Error', isTemporary && undefined);
	}

	/**
	 * Display an informational notification
	 *
	 * @param text Display text
	 * @param isTemporary Whether the notification should disappear automatically (true by default)
	 */
	public info(text: string, isTemporary = true) {
		this.notify(text, 'Info', isTemporary && undefined);
	}

	/**
	 * Display a success notification
	 *
	 * @param text Display text
	 * @param isTemporary Whether the notification should disappear automatically (true by default)
	 */
	public success(text: string, isTemporary = true) {
		this.notify(text, 'Success', isTemporary && undefined);
	}

	/**
	 * Display a warning notification
	 *
	 * @param text Display text
	 * @param isTemporary Whether the notification should disappear automatically (true by default)
	 */
	public warning(text: string, isTemporary = true) {
		this.notify(text, 'Warn', isTemporary && undefined);
	}

	/**
	 * Display a warning notification (disabled when `process.env.NODE_ENV === 'production'`)
	 *
	 * @param text Display text
	 */
	public devWarning(text: string) {
		if (this.isProd === false) {
			this.warning(`[DEV] ${text}`, false);
		}
	}

	/**
	 * Show a customizable notification to the user
	 *
	 * @param text Display text
	 * @param type Display type
	 * @param timeout Length in ms before notification disappears (`false` to set permanently)
	 * @param buttons Interaction points for the user
	 */
	public notify(text: string, type: NotificationType, timeout: false | number = 1500) {
		if (typeof text !== 'string' || text.length === 0) return;

		const notification = new Notification({ text, type });
		notification.show(timeout).then(() => {
			const index = this.notifications.findIndex(x => Object.is(x, notification));
			this.notifications.splice(index, 1);
		});

		this.notifications.push(notification);
		this.appendChild(notification);

		return notification;
	}
}
