import { Modal } from './modal';
import { User } from '../../models';

export class SignUpModal extends Modal<User> {
	constructor() {
		super({
			title: 'Sign Up',
			template: `
				<label for="email">Email</label>
				<input type="email" name="signup-email-input" id="signup-email" required class="form-control">
				<br />
				<label for="password">Password</label>
				<input type="password" name="signup-password-input" id="signup-password" class="form-control" minlength="8" title="Minimum 8 characters. Must include an uppercase & lowercase lettter, as well as a number & special character." required>
				<div class="alert alert-info">
					Minimum 8 characters. Must include an uppercase & lowercase lettter, as well as a number & special character.
				</div>
				<div class="form-row">
					<div class="col">
						<label for="first-name">First Name</label>
						<input type="text" name="signup-first-name-input" id="signup-first-name" class="form-control">
					</div>
					<div class="col">
						<label for="last-name">Last Name</label>
						<input type="text" name="signup-last-name-input" id="signup-last-name" class="form-control">
					</div>
				</div>
			`,
		});
		this.$submitBtn.value = 'Sign Up';
	}

	public onClose() {
		const { data, canClose } = super.onClose();

		if (canClose) {
			const passwordInput = this.querySelector('#signup-password') as HTMLInputElement;

			if (/(?=.*[!|@|#|$|%|^|&|*|(|)|_|+])(\d|[a-z]|[A-Z]|[!|@|#|$|%|^|&|*|(|)|_|+]){8,}/g.test(passwordInput.value)) {
				passwordInput.setCustomValidity('');
			} else {
				passwordInput.setCustomValidity('Minimum 8 characters. Must include an uppercase & lowercase lettter, as well as a number & special character.');
				this.$form.reportValidity();
				return { data, canClose: false };
			}

			data.Password = passwordInput.value;
			data.Email = (this.querySelector('#signup-email') as HTMLInputElement).value;
			data.FirstName = (this.querySelector('#signup-first-name') as HTMLInputElement).value;
			data.LastName = (this.querySelector('#signup-last-name') as HTMLInputElement).value;
		}

		return { data, canClose };
	}
}
