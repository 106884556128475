import { getTemplateCloneById } from '../util';

export class Landing extends HTMLElement {
	constructor() {
		super();
		this.appendChild(getTemplateCloneById('landing'));
		this.querySelector('#editor-dd')?.prepend(getTemplateCloneById('laptop-code-icon'));
		this.querySelector('#terminal-dd')?.prepend(getTemplateCloneById('terminal-icon'));
		this.querySelector('#file-explorer-dd')?.prepend(getTemplateCloneById('file-code-icon'));
		this.querySelector('#live-preview-dd')?.prepend(getTemplateCloneById('preview-icon'));
	}
}
